
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {
    this.Get('HR_Attendance_Map').then(response => {
      this.MEMWrite('Attendance_Map', response)
      this.Loading.pop() // Remove Loading flag
    }, error => {
      this.Loading.pop() // Remove Loading flag
      error.CookiesError = 'Error in reading Attendance'
      this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    })
  },
  data () {
    return {
      ShowFilter: false,
      Search: ''
    }
  },
  computed: {
    // Filters () :any {
    //   var EmployeesFilter :FilterObject = {
    //     Lists: [
    //       {
    //         Name: 'ssss',
    //         Visible: true,
    //         Options: [{ name: 'khaled' }, { name: 'mohammed' }],
    //         Selectors: ['name'],
    //         Res: 'Manufacturersc',
    //         DataPath: ['Notes'],
    //         ListPath: ['name']
    //       }
    //     ],
    //     Switches: [
    //       {
    //         Name: this.Translate('Manufacturer = 2'),
    //         Visible: true,
    //         Icon: 'Factory',
    //         Res: 'FinanceFilter_Manufacturers2',
    //         Value: 2,
    //         DataPath: ['ManufacturerID']
    //       }
    //     ]
    //   }
    //   return {
    //     Employeess: EmployeesFilter
    //   }
    // },
    Tabs () {
      return [
        { Name: this.Translate('Employees'), Link: 'Employees' },
        { Name: this.Translate('Departments'), Link: 'Departments' },
        { Name: this.Translate('Positions'), Link: 'Positions' },
        { Name: this.Translate('Warnings'), Link: 'Warnings' },
        { Name: this.Translate('Deductions'), Link: 'Deductions' },
        { Name: this.Translate('Attendance'), Link: 'Attendance' },
        { Name: this.Translate('Requests'), Link: 'AttendanceRequest' },
        { Name: this.Translate('Settings'), Link: 'HRSettings', Access: this.Access('HR_Settings_View') }
      ] as Array<CookiesTabs>
    },
    Name () {
      return this.Translate('HR')
    }
  }
})
