import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b094732"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "BigLoading"
}
const _hoisted_2 = { class: "D-Flex P1em Gap1" }
const _hoisted_3 = {
  key: 0,
  class: "DialogSection"
}
const _hoisted_4 = ["ToolTip"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesLoading = _resolveComponent("CookiesLoading")!
  const _component_CookiesDateInput = _resolveComponent("CookiesDateInput")!
  const _component_CookiesCheckBox = _resolveComponent("CookiesCheckBox")!
  const _component_CookiesFunctionalTable = _resolveComponent("CookiesFunctionalTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.Loading.length !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createVNode(_component_CookiesLoading)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CookiesDateInput, {
        class: _normalizeClass(_ctx.MEMRead('HR_Attendance_OnlyToday') ? 'Disabled' : ''),
        onChanged: _ctx.DateChanged,
        Res: 'HR_Attendance_CurrentMonth',
        Default: this.DateFormatter(new Date(), 'YYYY-MM'),
        Type: 'month'
      }, null, 8, ["class", "onChanged", "Default"]),
      _createVNode(_component_CookiesCheckBox, {
        onChanged: _ctx.DateChanged,
        style: {"margin-inline-start":"0"},
        Name: _ctx.Translate('Today'),
        Res: 'HR_Attendance_OnlyToday'
      }, null, 8, ["onChanged", "Name"])
    ]),
    _createVNode(_component_CookiesFunctionalTable, {
      Loading: _ctx.Loading,
      CookiesData: _ctx.FilteredData,
      TableSettings: _ctx.TableSettings
    }, {
      default: _withCtx(({ Field, item }) => [
        (_ctx.DeepFetcher(item, Field.Path))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.DeepFetcher(item, Field.Path), (attendance) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["Attendance", attendance.Type]),
                  key: attendance,
                  ToolTip: _ctx.TooltipDesign(attendance)
                }, null, 10, _hoisted_4))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["Loading", "CookiesData", "TableSettings"])
  ], 64))
}