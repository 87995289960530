
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Filter: { default: () => {}, type: Object as PropType<FilterObject> },
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.MEMWrite('HR_Attendance_CurrentMonth_Holder', this.DateFormatter(new Date(), 'YYYY-MM'))
    this.ReadCookiesData()
    // this.ChangeTableButtons(this.ParentThis, this.Buttons)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<Attendance>
    }
  },
  computed: {
    // Buttons () :Array<FunctionalTableButton> {
    //   return [
    //     {
    //       Name: 'New Attendance',
    //       this: this,
    //       Function: 'NewAttendance',
    //       Icon: 'Add',
    //       Visible: this.Access('HR_Attendance_Create')
    //     }
    //   ]
    // },
    TableSettings () :FunctionalTable {
      return {
        Fields: [
          // {
          //   Type: 'Index'
          // },
          {
            Type: 'DateTime',
            Name: 'AddedDate',
            Path: ['AddedDate'],
            Sort: ['AddedDate']
          },
          {
            Type: 'Time',
            Name: 'AddedDate',
            Path: ['AddedDate'],
            Sort: ['AddedDate'],
            Bold: true
          },
          {
            Type: 'StatusBox',
            StatusStyleColors: { 'Check In': '#066c9b', 'Check Out': '#437A6A' },
            StatusStyle: `color: ${this.Theme.BG2Fixed};`,
            Name: 'Type',
            Path: ['StatusType'],
            Sort: ['StatusType'],
            Class: 'F-CW2',
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Branch',
            Path: ['Employee', 'Branch', 'Name'],
            Sort: ['Employee', 'Branch', 'Name']
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['Employee', 'Name'],
            Sort: ['Employee', 'Name'],
            Secondary: {
              Path: ['Employee', 'ID'],
              Hash: '#'
            }
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    }
  },
  methods: {
    ReadCookiesData () {
      var Dates = this.CurrentDay()
      if (!this.MEMRead('HR_Attendance_OnlyToday')) {
        var date = new Date(this.MEMRead('HR_Attendance_CurrentMonth_Holder'))
        var Enddate = new Date(new Date(new Date(date).setMonth(date.getMonth() + 1)).setDate(0))
        var TimeZoneDiff = (new Date()).getTimezoneOffset() * 60000
        Dates = {
          From: new Date(date.setHours(0, 0, 0, 0) - TimeZoneDiff).toISOString(),
          To: new Date(Enddate.setHours(23, 59, 59, 999) - TimeZoneDiff).toISOString()
        }
      }
      this.Loading.push('Loading') // Add Loading flag
      this.Get('HR_Attendance', `?DateTime_From=${Dates.From}&DateTime_To=${Dates.To}`).then(response => {
        var Map = this.MEMRead('Attendance_Map')
        console.log(Map)
        this.CookiesData = response.map((value, index, array) => {
          return {
            ...value,
            StatusType: value?.Type && Map[value?.Type] ? Map[value?.Type]?.Name : value?.Type
          }
        })
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Attendance'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    DateChanged () {
      this.ReadCookiesData()
    },
    TooltipDesign (attendace :Attendance) {
      return `
        <div style="padding: 0.5em; display: flex; gap: 0.5em; flex-direction: column;">
          <div style="font-size:1em;">${this.Translate(attendace.Type)}</div>
          <div>${this.DateFormatter(attendace.DateTime, 'DD Month YYYY')}</div>
          <div>${this.DateFormatter(attendace.DateTime, 'Time')}</div>
        </div>
        `
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    }
  }
})
