
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} as DialogData }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      Employees: [] as Array<Employee>,
      OriginalData: {} as any,
      Saving: false,
      Loading: [],
      ErrorsVisibilty: false,
      DialogMapper: [
        { Path: ['Employee', 'Name'], Res: 'D_Attendance_Employee', ValuePath: 'ID', SavingPath: 'EmployeeID' },
        { Path: 'StartTime', Res: 'D_Attendance_StartTime' },
        { Path: 'EndTime', Res: 'D_Attendance_EndTime' }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    ReadEmployees () {
      this.Get('HR_Employees').then(response => {
        this.Employees = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Employees'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveData () {
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'HR_Attendance',
        OriginalData: this.OriginalData,
        PatchAPI: 'HR_Attendance',
        Editing: this.DialogData?.Editing,
        PatchQuery: '/' + this.DialogData?.Data?.ID
      })
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    }
  },
  mounted () {
    this.ReadEmployees()
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
    if (this.DialogData?.Editing) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData?.Data)
      this.Console(this.OriginalData)
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadEmployees()
      }
    }
  }
})
