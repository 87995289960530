
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Default: { default: '' },
    Type: { default: 'date', Type: String as PropType<DateType> }, // date, time, week, duration
    Res: { default: null, type: String },
    FixedWidth: { default: '', Type: String },
    MinWidth: { default: '1.6em', Type: String },
    Name: { default: '', Type: String },
    PreserveMemory: { default: false, Type: Boolean },
    DefaultReset: { default: false },
    HoursMax: { default: 24, type: Number },
    MinutesMax: { default: 59, type: Number }
  },
  emits: {
  },
  data () {
    return {
    }
  },
  created () {
    if (this.MEMRead(this.Res)) {
      if (this.Type !== 'time') {
        this.MEMWrite(this.Res, this.DateFormatter(this.MEMRead(this.Res), 'DD-MM-YYYY'))
      } else {
        var hours = new Date(this.MEMRead(this.Res)).getHours()
        var minutes = new Date(this.MEMRead(this.Res)).getMinutes()
        var formattedHours = hours.toString().padStart(2, '0')
        var formattedMinutes = minutes.toString().padStart(2, '0')
        var FixedTime = formattedHours + ':' + formattedMinutes
        this.MEMWrite(this.Res + '_Holder', FixedTime)
      }
    }
  },
  mounted () {
    if (this.Type === 'date' && this.MEMRead(this.Res)) {
      setTimeout(() => {
        if (this.MEMRead(this.Res)) this.MEMWrite(this.Res, this.MEMRead(this.Res).slice(0, 10))
      }, 200)
    }
    if (this.Default && this.Type === 'time') {
      var hours = new Date(this.Default).getHours()
      var minutes = new Date(this.Default).getMinutes()
      var formattedHours = hours.toString().padStart(2, '0')
      var formattedMinutes = minutes.toString().padStart(2, '0')
      var FixedTime = formattedHours + ':' + formattedMinutes
      this.MEMWrite(this.Res + '_Holder', FixedTime)
    }
    if (this.Default && this.Type === 'duration' && this.Default?.replace) this.MEMWrite(this.Res, this.Default.replace('.000000', ''))
    // if (this.Default) this.MEMWrite(this.Res, this.Default)
    this.RestDuration()
    // if (!this.MEMRead(this.Res)) this.MEMWrite(this.Res, '')
  },
  unmounted () {
    if (this.Res && !this.PreserveMemory) {
      this.MEMClean(this.Res)
    }
  },
  methods: {
    TimeFixer (minutes :any, max :number) {
      // // Ensure that the input is a number
      // minutes = parseInt(minutes, 10)

      // // Check if the value is a valid minute (between 0 and 59)
      // if (isNaN(minutes) || minutes < 0 || minutes > max) {
      //   return 'Invalid input'
      // }
      // Use the padStart method to ensure a minimum length of 2, adding a leading zero if necessary
      return minutes.toString().padStart(2, '0')
    },
    RestDuration () {
      if (this.Type === 'duration') {
        var CurrentTime = this.MEMRead(this.Res)
        if (CurrentTime?.split) this.MEMWrite(this.Res + '_hours', parseInt(CurrentTime?.split(':')[0]))
        if (CurrentTime?.split) this.MEMWrite(this.Res + '_minutes', parseInt(CurrentTime?.split(':')[1]))
      }
    }
  },
  computed: {
    CurrentValue () {
      return this.$store.state.CookiesMemory[this.Type === 'time' ? this.Res + '_Holder' : this.Res]
    },
    DuratioValue () {
      if (this.Type !== 'duration') return ''
      return (this.MEMRead(this.Res + '_hours') ? this.MEMRead(this.Res + '_hours').toString().padStart(2, '0') : '00') + ':' + (this.MEMRead(this.Res + '_minutes') ? this.MEMRead(this.Res + '_minutes').toString().padStart(2, '0') : '00')
    }
  },
  watch: {
    CurrentValue: function (newVal, oldVal) { // watch it
      if (newVal?.indexOf('.000000') > -1) {
        this.MEMWrite(this.Res, this.MEMRead(this.Res)?.replace('.000000', ''))
      }
      if (this.Type === 'time') {
        try {
          var dateVal = new Date()
          var Time = this.MEMRead(this.Res + '_Holder')
          dateVal.setHours(Time?.split(':')[0], Time?.split(':')[1], 0)
          this.MEMWrite(this.Res, dateVal?.toISOString())
        } catch (error) {
          console.error(error)
        }
      }
    },
    DuratioValue () {
      if (this.Type === 'duration') {
        this.MEMWrite(this.Res, this.DuratioValue)
        this.$emit('changed', this.DuratioValue)
      }
    }
  }
})
