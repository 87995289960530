
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
// import router from './Apps/ALL/router/index'
const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  components: {},
  created () {
    const favicon = document.getElementById('FavIcon') as HTMLLinkElement
    favicon.href = this.$store.state.CookiesConfig.FavIco
    this.MEMWrite('GlobalSaving', [])
    this.MEMWrite('GlobalSavingDone', [])
    this.MEMWrite('GlobalSavingFailed', [])
    fetch('/Cookies.config.json')
      .then((CustomConfigRes) => {
        if (CustomConfigRes.ok) {
          CustomConfigRes.json()
            .then((CustomConfig) => {
              console.log('CustomConfig')
              this.$store.state.CookiesCustomConfig = CustomConfig
              this.AfterCustomConfig()
            })
        }
      })
      .catch((error) => {
        console.error(error)
        this.AfterCustomConfig()
      })
  },
  beforeMount () {
    this.TimeBeforeMounted()
  },
  mounted () {
    const favicon = document.getElementById('FavIcon') as HTMLLinkElement
    favicon.href = this.$store.state.CookiesConfig.FavIco
    setTimeout(() => {
      window.scrollTo(0, 1)
    }, 300)
  },
  methods: {
    AfterCustomConfig () {
      var LocalConfig = this.LocalRead('CookiesDefaultConfig')
      if (LocalConfig !== null) {
        this.$store.state.CookiesConfig = {
          ...this.$store.state.CookiesConfig,
          ...LocalConfig
        }
      }
      this.CookiesAuthorization()
    },
    ToolTipSence () {
      this.$store.state.ToolTipElement = document.querySelector('[ToolTip]:hover')
    }
  },
  computed: {
    TabHeaderShadower () {
      return this.$store.getters.CurrentTheme === 'light' ? 'rgba(0, 0, 0, 0.20)' : 'rgba(0, 0, 0, 0.17)'
    },
    Maintenance () {
      return this.$store.state.CookiesConfig.MaintenanceMode === 'true'
    },
    VersionChecker () {
      var ServerVersion = this.$store.state.CookiesVueServerVersion
      var CurrentVersion = this.$store.state.CookiesVersion
      if (!ServerVersion) return false
      if (parseInt(ServerVersion.split('.')[0]) > parseInt(CurrentVersion.split('.')[0])) return true
      if (parseInt(ServerVersion.split('.')[0]) < parseInt(CurrentVersion.split('.')[0])) return false
      if (parseInt(ServerVersion.split('.')[1]) > parseInt(CurrentVersion.split('.')[1])) return true
      if (parseInt(ServerVersion.split('.')[1]) < parseInt(CurrentVersion.split('.')[1])) return false
      if (parseInt(ServerVersion.split('.')[2]) > parseInt(CurrentVersion.split('.')[2])) return true
      return false
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "924eb534": (_ctx.$store.getters.CurrentTheme),
  "057f05a9": (_ctx.$store.getters.Direction),
  "e0e49214": (_ctx.Theme.color3),
  "7c86dd02": (_ctx.Theme.colorBold),
  "e56a11a6": (_ctx.$store.getters.DefaultTextAlign),
  "c97a78f4": (_ctx.Theme.UserColors.main),
  "3fda79fe": (_ctx.Theme.UserColors.second),
  "6508fd8c": (_ctx.Theme.UserColors.third),
  "6b0e2eb2": (_ctx.Theme.UserColors.fourth),
  "e0e49218": (_ctx.Theme.color1),
  "e0e49216": (_ctx.Theme.color2),
  "142bcc86": (_ctx.Theme.BG1),
  "142bcc87": (_ctx.Theme.BG2),
  "142bcc88": (_ctx.Theme.BG3),
  "142bcc89": (_ctx.Theme.BG4),
  "142bcc8a": (_ctx.Theme.BG5),
  "142bcc8b": (_ctx.Theme.BG6),
  "142bcc8c": (_ctx.Theme.BG7),
  "142bcc8d": (_ctx.Theme.BG8),
  "57ede2e4": (_ctx.Theme.BG1Fixed),
  "548431a6": (_ctx.Theme.BG2Fixed),
  "511a8068": (_ctx.Theme.BG3Fixed),
  "4db0cf2a": (_ctx.Theme.BG4Fixed),
  "4a471dec": (_ctx.Theme.BG5Fixed),
  "46dd6cae": (_ctx.Theme.BG6Fixed),
  "4373bb70": (_ctx.Theme.BG7Fixed),
  "400a0a32": (_ctx.Theme.BG8Fixed),
  "de068ec2": (_ctx.Theme.InputColor),
  "dfc8421e": (_ctx.Theme.UserColors.Dimmed),
  "11372ac6": (_ctx.Theme.UserColors.Gradient),
  "73d85d89": (_ctx.Theme.Shade0),
  "73d85d8a": (_ctx.Theme.Shade1),
  "73d85d8b": (_ctx.Theme.Shade2),
  "73d85d8c": (_ctx.Theme.Shade3),
  "73d85d8d": (_ctx.Theme.Shade4),
  "ea963b58": (_ctx.Theme.BackBlur),
  "433e9cc8": (_ctx.Theme.UserColors.main_25),
  "3764a7c0": (_ctx.Theme.UserColors.third_50),
  "67f753c3": (_ctx.Theme.Light3),
  "2e605ffc": (_ctx.DirectionStyler.borderLeft),
  "31809181": (_ctx.DirectionStyler.borderRight),
  "6b58b50e": (_ctx.TabHeaderShadower),
  "4050de46": (_ctx.CurrentBackgroundColor),
  "e420f8be": (_ctx.DirectionStyler.first),
  "67af7f85": (_ctx.DirectionStyler.last),
  "e9e16538": (`url(${require("@/assets/elements/HorizontalEdgesMask.svg")})`)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__